import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import oval1 from "@images/oval.svg"

// import "@components/svg-decorations/svg-decorations.scss"
import WaveTop from "@components/svg-decorations/waveTop"
import WaveBottom from "@components/svg-decorations/waveBottom"
import CornerAccent from "@components/svg-decorations/cornerAccent"

import {
  LazyLoadImage as img,
  LazyLoadComponent,
} from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import CaseStudyScroll from "@components/swipers/caseStudyScroll"

import { Fade, Slide } from "react-reveal"

// Inline styling

const termsContent = {
  padding: "10rem 0 0 0",
  position: "relative",
}

// end of styling

const ProcessThesis = ({ location, data }) => {
  const caseStudy = data.allSanityCaseStudy.edges
  const bottomStudy = caseStudy.filter(
    item => item.node.caseStudyPosition === "bottom"
  )
  return (
    <>
      <Layout location={location}>
        <SEO title="Process Thesis" description="" />
        <div className="terms-content" style={termsContent}>
          <img className="oval-svg" src={oval1} alt="Oval svg" />

          <div className="container">
            <div className="intro text-center mb-5">
              <div className="col-md-10 mx-auto">
                <h1 className="text-primary-gradient text-center mb-3">
                  Watch the video in full, then click get a price to determine
                  if we are a good fit for each other...
                </h1>
                <h5 className="text-secondary">
                  Also, please download the written thesis and working model...
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7">
                <div className="embed-container">
                  <iframe
                    src="https://player.vimeo.com/video/308469803"
                    title="VSL"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  />
                </div>
              </div>
              <div className="col-md-5">
                <h3>
                  How To Turn Your High Ticket Business Into A Sales Machine And
                  PROFITABLY Flood Your Calendar With Qualified Sales
                  Appointments (Guaranteed) Month Over Month Without Ads, Ad
                  Spend, or Buying Lead Lists -- and Without Wasting Time On
                  Unqualified Prospects
                </h3>
                <ul className="list-unstyled my-4">
                  <li>
                    <a href="/files/pro-plus-outbound.pdf">
                      <i
                        className="fa fa-file-pdf-o mr-2"
                        aria-hidden="true"
                      ></i>
                      Pro Plus Outbound Prospecting Action Flow
                    </a>
                  </li>
                  <li>
                    <a href="/files/pro-outbound.pdf">
                      <i
                        className="fa fa-file-pdf-o mr-2"
                        aria-hidden="true"
                      ></i>
                      Pro Outbound Prospecting Action Flow
                    </a>
                  </li>
                </ul>
                <p className="font-weight-bold">
                  Please Watch Video And Click "Get A Price"
                </p>
                <p>
                  <a className="btn btn-primary btn-lg" href="/quiz">
                    Get a Price
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div
            className="text-center"
            style={{ paddingTop: "100px", paddingBottom: "125px" }}
          >
            <div className="container">
              <Fade down>
                <h2 className="text-uppercase text-primary-gradient">
                  Thesis Steps
                </h2>
              </Fade>
            </div>
          </div>
          <div className="thesis-steps">
            <div className="thesis-step-section">
              <WaveTop />
              <WaveBottom />
              <div className="container">
                <Slide down>
                  <div className="thesis-step-title-wrapper">
                    <span className="font-weight-bold text-secondary-gradient">
                      STEP 1
                    </span>
                    <h3 className="display-4 thesis-activity-name font-weight-bold">
                      Validate the Profitability Of Cold Outreach With Your
                      Financial Model
                    </h3>
                  </div>
                </Slide>

                <div className="row justify-content-center">
                  <div className="col-10">
                    <div className="thesis-image-wrapper">
                      <CornerAccent />
                      <img
                        alt=""
                        className="img-fluid shadow-lg d-block mx-auto"
                        src={require("@images/ROI_Calculation_Image.jpg")}
                      />
                    </div>
                  </div>
                </div>
                <div className="row align-items-start no-gutters py-5">
                  <div className="col-md-6 mb-4">
                    <Slide left>
                      <div>
                        <div className="thesis-old">
                          <h6 className="text-light-blue text-uppercase mb-3">
                            Old wAY
                          </h6>
                          <ul className="checked list-unstyled pb-5">
                            <li>You hear about some new way to market.</li>
                            <li>
                              You find the cheapest way possible to execute.
                            </li>
                            <li>
                              You’d spend time and money trying to figure it out
                              or test it.
                            </li>
                            <li>You see activity, but no sales...</li>
                            <li>
                              Expectations start to set in, but they may not
                              align with true profitability or dollar for dollar
                              outcomes.
                            </li>
                            <li>
                              No one has run the true math before starting.
                            </li>
                          </ul>
                          <h6 className="text-light-blue text-uppercase mb-3">
                            Old RESULT
                          </h6>
                          <ul className="checked list-unstyled">
                            <li>
                              The scope of what’s needed is greater than you
                              thought or realized.
                            </li>
                            <li>
                              The low-budget company doesn’t deliver any results
                              that move the needle.
                            </li>
                            <li>Time is wasted. Money is wasted.</li>
                            <li>
                              The activity is an equivalent of an empty carb.
                              It’s busy, but doesn’t produce.
                            </li>
                            <li>
                              A true test of the marketing channel is never
                              actually done.
                            </li>
                            <li>
                              Expectations remain out of line. All parties are
                              disappointed. ROI is never achieved.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Slide>
                  </div>
                  <div className="col-md-6 mb-4">
                    <Slide right>
                      <div>
                        <div className="thesis-new shadow-lg-dark">
                          <h6 className="text-light-blue text-uppercase mb-3">
                            New Way
                          </h6>
                          <ul className="checked list-unstyled pb-5">
                            <li>
                              You follow a proven path to success with
                              high-quality service providers.
                            </li>
                            <li>
                              You define expectations, goals, and tactics needed
                              to reach those goals.
                            </li>
                            <li>
                              You validate the ROI math before beginning, and
                              eliminate most of the risk.
                            </li>
                            <li>
                              Activity is based on metrics and executed with
                              precision and within budget.
                            </li>
                            <li>
                              ROI is calculated monthly and quarterly against
                              goals.
                            </li>
                            <li>Analytics inform the decisions.</li>
                          </ul>
                          <h6 className="text-light-blue text-uppercase mb-3">
                            New RESULT
                          </h6>
                          <ul className="checked list-unstyled">
                            <li>
                              Following a proven path eliminates trial and
                              error. Costs are lower by doing it right the first
                              time.
                            </li>
                            <li>
                              Expectations are in alignment. Tactics are in
                              alignment with broader strategy.
                            </li>
                            <li>
                              Lowered risk leaves room for proper testing and
                              time.
                            </li>
                            <li>
                              Success is benchmarked, all activities are a means
                              to an end.
                            </li>
                            <li>
                              Appointments are booked. SDRs spend their time
                              closing deals.
                            </li>
                            <li>Sales are made. Goals are hit.</li>
                          </ul>
                        </div>
                      </div>
                    </Slide>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <div className="text-center thesis-insight">
                      <h6 className="text-secondary-gradient text-uppercase">
                        Unique Useful Insight
                      </h6>
                      <p className="py-3">
                        Contrary to popular entrepreneur rhetoric, "do-overs"
                        are incredibly costly - due to the compounding effect of
                        money.
                      </p>
                      <Link to="/quiz" className="btn btn-primary btn-lg">
                        Get a Price
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="thesis-step-section">
              <WaveTop />
              <WaveBottom />
              <div className="container">
                <Slide down>
                  <div className="thesis-step-title-wrapper">
                    <span className="font-weight-bold text-white">STEP 2</span>
                    <h3 className="display-4 thesis-activity-name font-weight-bold">
                      Craft A Laser-Focussed Lead List in LinkedIn Sales
                      Navigator
                    </h3>
                  </div>
                </Slide>

                <div className="row justify-content-center">
                  <div className="col-10">
                    <div className="thesis-image-wrapper">
                      <CornerAccent />
                      <img
                        alt=""
                        className="img-fluid shadow-lg d-block mx-auto"
                        src={require("@images/Sales_Navigator.jpg")}
                      />
                    </div>
                  </div>
                </div>
                <div className="row align-items-start no-gutters py-5">
                  <div className="col-md-6 mb-4">
                    <Slide left>
                      <div>
                        <div className="thesis-old">
                          <h6 className="text-light-blue text-uppercase mb-3">
                            Old wAY
                          </h6>
                          <ul className="checked list-unstyled pb-5">
                            <li>
                              In the past, lead targeting was a complete
                              guessing game.
                            </li>
                            <li>
                              If you were running ads, you had no idea who they
                              were actually going to.
                            </li>
                            <li>
                              If you bought lists, you had no way of knowing if
                              it was valid.
                            </li>
                            <li>
                              Lead targeting was a catch-all with limited
                              filters and often high margin of error.
                            </li>
                            <li>
                              Sales Navigator is confusing, so you’d have to
                              rely on sketchy LinkedIn companies to help target,
                              and you never achieve qualified fit because the
                              list is never quite right and the low-budget
                              companies never review, change, test, or modify
                              the search once it’s built...
                            </li>
                          </ul>
                          <h6 className="text-light-blue text-uppercase mb-3">
                            Old RESULT
                          </h6>
                          <ul className="checked list-unstyled">
                            <li>
                              This meant you just had to ‘trust the process’...
                            </li>
                            <li>
                              Large budgets end up being spent just to send ads
                              to an unqualified list.
                            </li>
                            <li>
                              You never could read the names on the list, see
                              their profiles, check their websites, or validate
                              their lead quality.
                            </li>
                            <li>
                              The uncomfortable margin of error in the lead list
                              means that any campaign you run to those leads is
                              completely pointless.
                            </li>
                            <li>
                              You end up with a stale list that was never done
                              correctly, and you end up missing the mark on your
                              outbound campaign because of it.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Slide>
                  </div>
                  <div className="col-md-6 mb-4">
                    <Slide right>
                      <div>
                        <div className="thesis-new shadow-lg-dark">
                          <h6 className="text-light-blue text-uppercase mb-3">
                            New Way
                          </h6>
                          <ul className="checked list-unstyled pb-5">
                            <li>
                              Not only can you target based on Sales Navigator’s
                              advanced filters, but there are other means of
                              acquiring amazing lead lists as well.
                            </li>
                            <li>
                              This means you can target demographically (title,
                              keywords, industry, etc.) and also by technology
                              used -- depending on what works best.
                            </li>
                            <li>
                              You now start with a list that you can vet,
                              change, sift-through, and approve. Every name,
                              every profile, every domain, is now visible.
                            </li>
                            <li>
                              Your list can be updated as needed, and is never
                              stale or irrelevant.
                            </li>
                          </ul>
                          <h6 className="text-light-blue text-uppercase mb-3">
                            New RESULT
                          </h6>
                          <ul className="checked list-unstyled">
                            <li>New doors are opened for targeting…</li>
                            <li>
                              You’re able to hit markets that aren’t typically
                              hit with prospecting.
                            </li>
                            <li>
                              You’re left with an amazing list that is full of
                              demographically qualified prospects that simply
                              need to be prospected.
                            </li>
                            <li>Alignment is maintained.</li>
                            <li>Lead quality is never a concern.</li>
                            <li>
                              Maximum flexibility means that you have the
                              freedom to change your targeting along with the
                              changing needs of your business, and never
                              sacrifice speed or quality.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Slide>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <div className="text-center thesis-insight">
                      <h6 className="text-light-blue text-uppercase mb-3">
                        Unique Useful Insight
                      </h6>
                      <p className="py-3">
                        The most critical step in any successful Outbound Sales
                        initiative is to compile a hyper targeted leads list.
                      </p>
                      <Link to="/quiz" className="btn btn-primary btn-lg">
                        Get a Price
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="thesis-step-section">
              <WaveTop />
              <WaveBottom />
              <div className="container">
                <Slide down>
                  <div className="thesis-step-title-wrapper">
                    <span className="font-weight-bold text-secondary-gradient">
                      STEP 3
                    </span>
                    <h3 className="display-4 thesis-activity-name font-weight-bold">
                      Create a Highly Relevant Outreach Message Sequence
                    </h3>
                  </div>
                </Slide>

                <div className="row justify-content-center">
                  <div className="col-10">
                    <div className="thesis-image-wrapper">
                      <CornerAccent />
                      <img
                        alt=""
                        className="img-fluid shadow-lg d-block mx-auto"
                        src={require("@images/Thesis-3.jpg")}
                      />
                    </div>
                  </div>
                </div>
                <div className="row align-items-start no-gutters py-5">
                  <div className="col-md-6 mb-4">
                    <Slide left>
                      <div>
                        <div className="thesis-old">
                          <h6 className="text-light-blue text-uppercase mb-3">
                            Old wAY
                          </h6>
                          <ul className="checked list-unstyled pb-5">
                            <li>
                              In the past, lead targeting was a complete
                              guessing game.
                            </li>
                            <li>
                              If you were running ads, you had no idea who they
                              were actually going to.
                            </li>
                            <li>
                              If you bought lists, you had no way of knowing if
                              it was valid.
                            </li>
                            <li>
                              Lead targeting was a catch-all with limited
                              filters and often high margin of error.
                            </li>
                            <li>
                              Sales Navigator is confusing, so you’d have to
                              rely on sketchy LinkedIn companies to help target,
                              and you never achieve qualified fit because the
                              list is never quite right and the low-budget
                              companies never review, change, test, or modify
                              the search once it’s built...
                            </li>
                          </ul>
                          <h6 className="text-light-blue text-uppercase mb-3">
                            Old RESULT
                          </h6>
                          <ul className="checked list-unstyled">
                            <li>
                              This meant you just had to ‘trust the process’...
                            </li>
                            <li>
                              Large budgets end up being spent just to send ads
                              to an unqualified list.
                            </li>
                            <li>
                              You never could read the names on the list, see
                              their profiles, check their websites, or validate
                              their lead quality.
                            </li>
                            <li>
                              The uncomfortable margin of error in the lead list
                              means that any campaign you run to those leads is
                              completely pointless.
                            </li>
                            <li>
                              You end up with a stale list that was never done
                              correctly, and you end up missing the mark on your
                              outbound campaign because of it.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Slide>
                  </div>
                  <div className="col-md-6 mb-4">
                    <Slide right>
                      <div>
                        <div className="thesis-new shadow-lg-dark">
                          <h6 className="text-light-blue text-uppercase mb-3">
                            New Way
                          </h6>
                          <ul className="checked list-unstyled pb-5">
                            <li>
                              Not only can you target based on Sales Navigator’s
                              advanced filters, but there are other means of
                              acquiring amazing lead lists as well.
                            </li>
                            <li>
                              This means you can target demographically (title,
                              keywords, industry, etc.) and also by technology
                              used -- depending on what works best.
                            </li>
                            <li>
                              You now start with a list that you can vet,
                              change, sift-through, and approve. Every name,
                              every profile, every domain, is now visible.
                            </li>
                            <li>
                              Your list can be updated as needed, and is never
                              stale or irrelevant.
                            </li>
                          </ul>
                          <h6 className="text-light-blue text-uppercase mb-3">
                            New RESULT
                          </h6>
                          <ul className="checked list-unstyled">
                            <li>New doors are opened for targeting…</li>
                            <li>
                              You’re able to hit markets that aren’t typically
                              hit with prospecting.
                            </li>
                            <li>
                              You’re left with an amazing list that is full of
                              demographically qualified prospects that simply
                              need to be prospected.
                            </li>
                            <li>Alignment is maintained.</li>
                            <li>Lead quality is never a concern.</li>
                            <li>
                              Maximum flexibility means that you have the
                              freedom to change your targeting along with the
                              changing needs of your business, and never
                              sacrifice speed or quality.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Slide>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <div className="text-center thesis-insight">
                      <h6 className="text-secondary-gradient text-uppercase">
                        Unique Useful Insight
                      </h6>
                      <p className="py-3">
                        The enemy of outbound sales is the spam box…If you’re
                        not careful, not only will the vast majority of your
                        outbound emails end up in SPAM, but you risk your domain
                        becoming blacklisted.
                      </p>
                      <Link to="/quiz" className="btn btn-primary btn-lg">
                        Get a Price
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="casestudy-section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8 text-center">
                  <h2 className="display-4 text-secondary-gradient">
                    Again, this is niche who...
                  </h2>
                </div>
              </div>
            </div>
            <div className="component">
              <LazyLoadComponent>
                <CaseStudyScroll data={bottomStudy} />
              </LazyLoadComponent>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ProcessThesis

export const processThesisQuery = graphql`
  query processThesisQuery {
    allSanityCaseStudy(sort: { fields: title, order: ASC }) {
      edges {
        node {
          id
          title
          logo {
            id
            title
            logo {
              alt
              asset {
                id
                fixed(width: 200) {
                  src
                }
              }
            }
          }
          headshots {
            id
            image {
              alt
              asset {
                id
                fixed(width: 200, height: 200) {
                  src
                }
              }
            }
          }
          graphImage {
            alt
            asset {
              fluid {
                src
              }
            }
          }
          state1
          state2
          timeFrame
          caseStudyPosition
        }
      }
    }
  }
`
