import React from "react"

const CornerAccent = () => (
	<div className="corner-svg-accent">
		<svg
			className="img_accent_top_right"
			width="233"
			height="248"
			viewBox="0 0 233 248"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.890137"
				y="51.1089"
				width="44.5325"
				height="277.587"
				rx="22.2662"
				transform="rotate(-45 0.890137 51.1089)"
				fill="url(#linear_a)"
			/>
			<rect
				x="71"
				y="47.7446"
				width="67.5211"
				height="161.26"
				rx="33.7606"
				transform="rotate(-45 71 47.7446)"
				fill="url(#linear_b)"
			/>
			<defs>
				<linearGradient
					id="linear_a"
					x1="0.890137"
					y1="51.1089"
					x2="83.8013"
					y2="63.0891"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#0CFFF5" />
					<stop offset="1" stopColor="#41B9FA" />
				</linearGradient>
				<linearGradient
					id="linear_b"
					x1="71"
					y1="47.7446"
					x2="183.357"
					y2="90.1171"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#0CFFF5" />
					<stop offset="1" stopColor="#41B9FA" />
				</linearGradient>
			</defs>
		</svg>
		<svg
			className="img_accent_bottom_left"
			width="415"
			height="415"
			viewBox="0 0 415 415"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				y="113.72"
				width="160.825"
				height="425.631"
				rx="80.4123"
				transform="rotate(-45 0 113.72)"
				fill="url(#linear_c)"
			/>
			<rect
				x="19"
				y="232.86"
				width="53.5426"
				height="212.938"
				rx="26.7713"
				transform="rotate(-45 19 232.86)"
				fill="url(#linear_3)"
			/>
			<defs>
				<linearGradient
					id="linear_c"
					x1="0"
					y1="113.72"
					x2="273.948"
					y2="206.951"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#0CFFF5" />
					<stop offset="1" stopColor="#41B9FA" />
				</linearGradient>
				<linearGradient
					id="linear_3"
					x1="19"
					y1="232.86"
					x2="115.803"
					y2="254.783"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#0CFFF5" />
					<stop offset="1" stopColor="#41B9FA" />
				</linearGradient>
			</defs>
		</svg>
	</div>
)

export default CornerAccent
