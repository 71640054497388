import React from "react"

const WaveTop = () => (
	<div className="wave-top">
		{/* screens < 1440 */}
		<svg
			className="wave_lg"
			width="100%"
			height="259"
			viewBox="0 0 1440 259"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path fill="#Ecf0f3">
				<animate
					repeatCount="indefinite"
					fill="freeze"
					attributeName="d"
					dur="10s"
					values="
	M0 0L60 28.6298C120 57.9255 240 115.185 360 119.846C480 124.506 600 76.5681 720 52.599C840 28.6298 960 28.6298 1080 43.2776C1200 57.9255 1320 86.5553 1380 100.537L1440 115.185V259H1380C1320 259 1200 259 1080 259C960 259 840 259 720 259C600 259 480 259 360 259C240 259 120 259 60 259H0V0Z;

	M0 78.6805L60 54.6378C120 30.5952 240 -17.49 360 6.55263C480 30.5952 600 126.766 720 156.819C840 186.872 960 150.808 1080 114.744C1200 78.6805 1320 42.6165 1380 24.5846L1440 6.55263V259H1380C1320 259 1200 259 1080 259C960 259 840 259 720 259C600 259 480 259 360 259C240 259 120 259 60 259H0V78.6805Z;

	M0 0L60 28.6298C120 57.9255 240 115.185 360 119.846C480 124.506 600 76.5681 720 52.599C840 28.6298 960 28.6298 1080 43.2776C1200 57.9255 1320 86.5553 1380 100.537L1440 115.185V259H1380C1320 259 1200 259 1080 259C960 259 840 259 720 259C600 259 480 259 360 259C240 259 120 259 60 259H0V0Z
	"
				/>
			</path>
		</svg>

		{/* larger screends >= 1440 */}
		<svg
			className="wave_xl"
			width="100%"
			height="100%"
			viewBox="0 0 1440 259"
			enableBackground="new 0 0 1440 259"
			xmlSpace="preserve"
			preserveAspectRatio="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path fill="#Ecf0f3">
				<animate
					repeatCount="indefinite"
					fill="freeze"
					attributeName="d"
					dur="10s"
					values="
	M0 0L60 28.6298C120 57.9255 240 115.185 360 119.846C480 124.506 600 76.5681 720 52.599C840 28.6298 960 28.6298 1080 43.2776C1200 57.9255 1320 86.5553 1380 100.537L1440 115.185V259H1380C1320 259 1200 259 1080 259C960 259 840 259 720 259C600 259 480 259 360 259C240 259 120 259 60 259H0V0Z;

	M0 78.6805L60 54.6378C120 30.5952 240 -17.49 360 6.55263C480 30.5952 600 126.766 720 156.819C840 186.872 960 150.808 1080 114.744C1200 78.6805 1320 42.6165 1380 24.5846L1440 6.55263V259H1380C1320 259 1200 259 1080 259C960 259 840 259 720 259C600 259 480 259 360 259C240 259 120 259 60 259H0V78.6805Z;

	M0 0L60 28.6298C120 57.9255 240 115.185 360 119.846C480 124.506 600 76.5681 720 52.599C840 28.6298 960 28.6298 1080 43.2776C1200 57.9255 1320 86.5553 1380 100.537L1440 115.185V259H1380C1320 259 1200 259 1080 259C960 259 840 259 720 259C600 259 480 259 360 259C240 259 120 259 60 259H0V0Z
	"
				/>
			</path>
		</svg>
	</div>
)

export default WaveTop
